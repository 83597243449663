<script>
	export let to;
	export let type = "default";
</script>

<style lang="scss">.un-link.is-default {
  color: inherit;
}
.un-link.is-default:hover {
  color: var(--color-text-interactive);
}
.un-link.is-implied {
  color: var(--color-content-default);
  text-decoration: none;
}
.un-link.is-implied:hover {
  text-decoration: underline;
}
.un-link.is-article {
  text-decoration: underline;
  color: var(--color-text-interactive);
}
/*# sourceMappingURL=src/components/Link.svelte.map */</style>

<a
	class="un-link"
	class:is-default="{type === 'default'}"
	class:is-article="{type === 'article'}"
	class:is-implied="{type === 'implied'}"
	href="{to}"
>
	<slot />
</a>
