<script>
	export let space = "3";
	export let mode = "";
	export let radius = "0";
	export let shadow = "0";
</script>

<style lang="scss">/* Map Design Tokens to SCSS Variables for easier SCSS design
   ========================================================================== */
/* Mixins & Functions
   ========================================================================== */
.box.has-space-1 {
  padding: var(--space-1);
}
.box.has-space-2 {
  padding: var(--space-2);
}
.box.has-space-3 {
  padding: var(--space-3);
}
.box.has-space-4 {
  padding: var(--space-4);
}
.box.has-space-5 {
  padding: var(--space-5);
}
.box.has-space-6 {
  padding: var(--space-6);
}
.box.has-space-7 {
  padding: var(--space-7);
}
.box.has-space-8 {
  padding: var(--space-8);
}
.box.has-radius-1 {
  border-radius: var(--size-border-radius-1);
}
.box.has-radius-2 {
  border-radius: var(--size-border-radius-2);
}
.box.has-radius-3 {
  border-radius: var(--size-border-radius-3);
}
.box.has-radius-4 {
  border-radius: var(--size-border-radius-4);
}
.box.has-shadow-1 {
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
          box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}
.box.has-shadow-2 {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5), 0 1px 2px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5), 0 1px 2px 0 rgba(0, 0, 0, 0.2);
}
.box.has-shadow-3 {
  -webkit-box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.5), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
          box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.5), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
}
.box.has-shadow-4 {
  -webkit-box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.5), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
          box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.5), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.box.is-mode-white {
  --color-text-default: var(--color-text-default-on-white);
  --color-text-muted: var(--color-text-muted-on-white);
  --color-text-disabled: var(--color-text-disabled-on-white);
  --color-text-interactive: var(--color-text-interactive-on-white);
  --color-text-error: var(--color-text-error-on-white);
  --color-bg-accent: var(--color-bg-accent-white);
  color: var(--color-text-default);
  background: var(--color-bg-white);
}
.box.is-mode-light {
  --color-text-default: var(--color-text-default-on-light);
  --color-text-muted: var(--color-text-muted-on-light);
  --color-text-disabled: var(--color-text-disabled-on-light);
  --color-text-interactive: var(--color-text-interactive-on-light);
  --color-text-error: var(--color-text-error-on-light);
  --color-bg-accent: var(--color-bg-accent-light);
  color: var(--color-text-default);
  background: var(--color-bg-light);
}
.box.is-mode-dark {
  --color-text-default: var(--color-text-default-on-dark);
  --color-text-muted: var(--color-text-muted-on-dark);
  --color-text-disabled: var(--color-text-disabled-on-dark);
  --color-text-interactive: var(--color-text-interactive-on-dark);
  --color-text-error: var(--color-text-error-on-dark);
  --color-bg-accent: var(--color-bg-accent-dark);
  color: var(--color-text-default);
  background: var(--color-bg-dark);
}
.box.is-mode-black {
  --color-text-default: var(--color-text-default-on-black);
  --color-text-muted: var(--color-text-muted-on-black);
  --color-text-disabled: var(--color-text-disabled-on-black);
  --color-text-interactive: var(--color-text-interactive-on-black);
  --color-text-error: var(--color-text-error-on-black);
  --color-bg-accent: var(--color-bg-accent-black);
  color: var(--color-text-default);
  background: var(--color-bg-black);
}
/*# sourceMappingURL=src/components/Box.svelte.map */</style>

<div
	class="box"
	class:has-space-1="{space === '1'}"
	class:has-space-2="{space === '2'}"
	class:has-space-3="{space === '3'}"
	class:has-space-4="{space === '4'}"
	class:has-space-5="{space === '5'}"
	class:has-space-6="{space === '6'}"
	class:has-space-7="{space === '7'}"
	class:has-space-8="{space === '8'}"
	class:is-mode-white="{mode === 'white'}"
	class:is-mode-light="{mode === 'light'}"
	class:is-mode-dark="{mode === 'dark'}"
	class:is-mode-black="{mode === 'black'}"
	class:has-radius-1="{radius === '1'}"
	class:has-radius-2="{radius === '2'}"
	class:has-radius-3="{radius === '3'}"
	class:has-radius-4="{radius === '4'}"
	class:has-shadow-1="{shadow === '1'}"
	class:has-shadow-2="{shadow === '2'}"
	class:has-shadow-3="{shadow === '3'}"
	class:has-shadow-4="{shadow === '4'}"
>
	<slot />
</div>
