<script>
	export let text;
</script>

<style lang="scss">/* Map Design Tokens to SCSS Variables for easier SCSS design
   ========================================================================== */
/* Mixins & Functions
   ========================================================================== */
.un-label__label {
  font-size: var(--type-scale-2);
  line-height: var(--line-height-2);
  letter-spacing: var(--tracking-2);
  font-weight: var(--weight-bold);
}
/*# sourceMappingURL=src/components/Label.svelte.map */</style>

<label class="un-label">
	<div class="un-label__label">{text}</div>
	<slot />
</label>
