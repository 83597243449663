<script>
	import clsx from "clsx";
	export let tag = "p";
	export let size = "3";
	export let weight = "normal";
	export let color = "default";

	let classes = clsx({
		"un-text": true,
		"is-size-1": size === "1",
		"is-size-2": size === "2",
		"is-size-3": size === "3",
		"is-size-4": size === "4",
		"is-size-5": size === "5",
		"is-size-6": size === "6",
		"is-size-7": size === "7",
		"is-size-8": size === "8",
		"is-size-9": size === "9",
		"is-size-10": size === "10",
		"is-size-11": size === "11",
		"is-size-12": size === "12",
		"is-size-13": size === "13",
		"is-weight-normal": weight === "normal",
		"is-weight-medium": weight === "medium",
		"is-weight-bold": weight === "bold",
		"is-color-default": color === "default",
		"is-color-muted": color === "muted",
		"is-color-disabled": color === "disabled",
	});
</script>

<style lang="scss">/* Map Design Tokens to SCSS Variables for easier SCSS design
   ========================================================================== */
/* Mixins & Functions
   ========================================================================== */
.un-text {
  margin: initial;
  font-weight: initial;
  font-family: var(--family-primary);
}
.un-text.is-size-1 {
  font-size: var(--type-scale-1);
  line-height: var(--line-height-1);
  letter-spacing: var(--tracking-1);
}
.un-text.is-size-2 {
  font-size: var(--type-scale-2);
  line-height: var(--line-height-2);
  letter-spacing: var(--tracking-2);
}
.un-text.is-size-3 {
  font-size: var(--type-scale-3);
  line-height: var(--line-height-3);
  letter-spacing: var(--tracking-3);
}
.un-text.is-size-4 {
  font-size: var(--type-scale-4);
  line-height: var(--line-height-4);
  letter-spacing: var(--tracking-4);
}
.un-text.is-size-5 {
  font-size: var(--type-scale-5);
  line-height: var(--line-height-5);
  letter-spacing: var(--tracking-5);
}
.un-text.is-size-6 {
  font-size: var(--type-scale-6);
  line-height: var(--line-height-6);
  letter-spacing: var(--tracking-6);
}
.un-text.is-size-7 {
  font-size: var(--type-scale-7);
  line-height: var(--line-height-7);
  letter-spacing: var(--tracking-7);
}
.un-text.is-size-8 {
  font-size: var(--type-scale-8);
  line-height: var(--line-height-8);
  letter-spacing: var(--tracking-8);
}
.un-text.is-size-9 {
  font-size: var(--type-scale-9);
  line-height: var(--line-height-9);
  letter-spacing: var(--tracking-9);
}
.un-text.is-size-10 {
  font-size: var(--type-scale-10);
  line-height: var(--line-height-10);
  letter-spacing: var(--tracking-10);
}
.un-text.is-size-11 {
  font-size: var(--type-scale-11);
  line-height: var(--line-height-11);
  letter-spacing: var(--tracking-11);
}
.un-text.is-size-12 {
  font-size: var(--type-scale-12);
  line-height: var(--line-height-12);
  letter-spacing: var(--tracking-12);
}
.un-text.is-weight-normal {
  font-weight: var(--weight-normal);
}
.un-text.is-weight-medium {
  font-weight: var(--weight-medium);
}
.un-text.is-weight-bold {
  font-weight: var(--weight-bold);
}
.un-text.is-color-default {
  color: var(--color-text-default);
}
.un-text.is-color-muted {
  color: var(--color-text-muted);
}
.un-text.is-color-disabled {
  color: var(--color-text-disabled);
}
/*# sourceMappingURL=src/components/Text.svelte.map */</style>

{#if tag === 'h1'}
	<h1 class="{classes}">
		<slot />
	</h1>
{:else if tag === 'h2'}
	<h2 class="{classes}">
		<slot />
	</h2>
{:else if tag === 'h3'}
	<h3 class="{classes}">
		<slot />
	</h3>
{:else if tag === 'h4'}
	<h4 class="{classes}">
		<slot />
	</h4>
{:else if tag === 'h5'}
	<h5 class="{classes}">
		<slot />
	</h5>
{:else if tag === 'h6'}
	<h6 class="{classes}">
		<slot />
	</h6>
{:else if tag === 'p'}
	<p class="{classes}">
		<slot />
	</p>
{/if}
