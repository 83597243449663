<script>
	export let src;
	export let alt;
	export let size = "4";
	export let radius = "0";
</script>

<style lang="scss">/* Map Design Tokens to SCSS Variables for easier SCSS design
   ========================================================================== */
/* Mixins & Functions
   ========================================================================== */
.un-fixed-image {
  overflow: hidden;
}
.un-fixed-image.is-size-1 {
  max-width: var(--size-fixed-1);
  max-height: var(--size-fixed-1);
}
.un-fixed-image.is-size-2 {
  max-width: var(--size-fixed-2);
  max-height: var(--size-fixed-2);
}
.un-fixed-image.is-size-3 {
  max-width: var(--size-fixed-3);
  max-height: var(--size-fixed-3);
}
.un-fixed-image.is-size-4 {
  max-width: var(--size-fixed-4);
  max-height: var(--size-fixed-4);
}
.un-fixed-image.is-size-5 {
  max-width: var(--size-fixed-5);
  max-height: var(--size-fixed-5);
}
.un-fixed-image.is-size-6 {
  max-width: var(--size-fixed-6);
  max-height: var(--size-fixed-6);
}
.un-fixed-image.is-size-7 {
  max-width: var(--size-fixed-7);
  max-height: var(--size-fixed-7);
}
.un-fixed-image.has-radius-1 {
  border-radius: var(--size-border-radius-1);
}
.un-fixed-image.has-radius-2 {
  border-radius: var(--size-border-radius-2);
}
.un-fixed-image.has-radius-3 {
  border-radius: var(--size-border-radius-3);
}
.un-fixed-image.has-radius-4 {
  border-radius: var(--size-border-radius-4);
}
.un-fixed-image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  max-width: 100%;
  display: inline-block;
}
/*# sourceMappingURL=src/components/FixedImage.svelte.map */</style>

<figure
	class="un-fixed-image"
	class:is-size-1="{size === '1'}"
	class:is-size-2="{size === '2'}"
	class:is-size-3="{size === '3'}"
	class:is-size-4="{size === '4'}"
	class:is-size-5="{size === '5'}"
	class:is-size-6="{size === '6'}"
	class:is-size-7="{size === '7'}"
	class:is-size-8="{size === '8'}"
	class:has-radius-1="{radius === '1'}"
	class:has-radius-2="{radius === '2'}"
	class:has-radius-3="{radius === '3'}"
	class:has-radius-4="{radius === '4'}"
>
	<img src="{src}" alt="{alt}" />
</figure>
