<style lang="scss">/* Map Design Tokens to SCSS Variables for easier SCSS design
   ========================================================================== */
/* Mixins & Functions
   ========================================================================== */
.un-media-object {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.un-media-object__media {
  -ms-flex-preferred-size: auto;
      flex-basis: auto;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.un-media-object__content {
  -ms-flex-preferred-size: auto;
      flex-basis: auto;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -ms-flex-negative: 1;
      flex-shrink: 1;
  text-align: left;
}
.un-media-object__end {
  margin-left: auto;
}
/*# sourceMappingURL=src/components/MediaObject.svelte.map */</style>

<div class="un-media-object">
	<div class="un-media-object__media">
		<slot name="media" />
	</div>
	<div class="un-media-object__content">
		<slot />
	</div>
	<div class="un-media-object__end">
		<slot name="end" />
	</div>
</div>
