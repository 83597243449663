<script>
	import cssVars from "svelte-css-vars";

	export let src;
	export let alt;
	export let ratio = "16/9";

	$: [width, height = width] = ratio.toString().split(/[x|\/]/);

	$: styleVars = {
		w: width,
		h: height,
	};
</script>

<style>
.un-frame {
	padding-bottom: calc(var(--w) / var(--h) * 100%);
	display: block;
	position: relative;
}

.un-frame img {
	overflow: hidden;
	position: absolute;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.un-frame img {
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	   object-fit: cover;
	max-width: 100%;
	display: inline-block;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL0ZyYW1lLnN2ZWx0ZSJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiO0FBQ0E7Q0FDQyxnREFBZ0Q7Q0FDaEQsY0FBYztDQUNkLGtCQUFrQjtBQUNuQjs7QUFFQTtDQUNDLGdCQUFnQjtDQUNoQixrQkFBa0I7Q0FDbEIsb0JBQWE7Q0FBYixvQkFBYTtDQUFiLGFBQWE7Q0FDYix3QkFBdUI7S0FBdkIscUJBQXVCO1NBQXZCLHVCQUF1QjtDQUN2Qix5QkFBbUI7S0FBbkIsc0JBQW1CO1NBQW5CLG1CQUFtQjtDQUNuQixNQUFNO0NBQ04sUUFBUTtDQUNSLFNBQVM7Q0FDVCxPQUFPO0FBQ1I7O0FBRUE7Q0FDQyxXQUFXO0NBQ1gsWUFBWTtDQUNaLG9CQUFpQjtJQUFqQixpQkFBaUI7Q0FDakIsZUFBZTtDQUNmLHFCQUFxQjtBQUN0QiIsImZpbGUiOiJzcmMvY29tcG9uZW50cy9GcmFtZS5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyJcbi51bi1mcmFtZSB7XG5cdHBhZGRpbmctYm90dG9tOiBjYWxjKHZhcigtLXcpIC8gdmFyKC0taCkgKiAxMDAlKTtcblx0ZGlzcGxheTogYmxvY2s7XG5cdHBvc2l0aW9uOiByZWxhdGl2ZTtcbn1cblxuLnVuLWZyYW1lIGltZyB7XG5cdG92ZXJmbG93OiBoaWRkZW47XG5cdHBvc2l0aW9uOiBhYnNvbHV0ZTtcblx0ZGlzcGxheTogZmxleDtcblx0anVzdGlmeS1jb250ZW50OiBjZW50ZXI7XG5cdGFsaWduLWl0ZW1zOiBjZW50ZXI7XG5cdHRvcDogMDtcblx0cmlnaHQ6IDA7XG5cdGJvdHRvbTogMDtcblx0bGVmdDogMDtcbn1cblxuLnVuLWZyYW1lIGltZyB7XG5cdHdpZHRoOiAxMDAlO1xuXHRoZWlnaHQ6IDEwMCU7XG5cdG9iamVjdC1maXQ6IGNvdmVyO1xuXHRtYXgtd2lkdGg6IDEwMCU7XG5cdGRpc3BsYXk6IGlubGluZS1ibG9jaztcbn1cbiJdfQ== */</style>

<div class="un-frame" use:cssVars="{styleVars}">
	<img src="{src}" alt="{alt}" />
</div>
