<style lang="scss">/* Map Design Tokens to SCSS Variables for easier SCSS design
   ========================================================================== */
/* Mixins & Functions
   ========================================================================== */
.markdown :global(h1:not(.un-text)) {
  font-size: var(--type-scale-10);
  line-height: var(--line-height-10);
  letter-spacing: var(--tracking-10);
  font-weight: var(--weight-bold);
  max-width: 65ch;
}
.markdown :global(h2:not(.un-text)) {
  font-size: var(--type-scale-8);
  line-height: var(--line-height-8);
  letter-spacing: var(--tracking-8);
  font-weight: var(--weight-bold);
  margin-top: 2rem;
  max-width: 65ch;
}
.markdown :global(h3:not(.un-text)) {
  font-size: var(--type-scale-6);
  line-height: var(--line-height-6);
  letter-spacing: var(--tracking-6);
  font-weight: var(--weight-bold);
  margin-top: 2rem;
  max-width: 65ch;
}
.markdown :global(h4:not(.un-text)) {
  font-size: var(--type-scale-5);
  line-height: var(--line-height-5);
  letter-spacing: var(--tracking-5);
  font-weight: var(--weight-bold);
  margin-top: 1.5rem;
  max-width: 65ch;
}
.markdown :global(h5:not(.un-text)) {
  font-size: var(--type-scale-5);
  line-height: var(--line-height-5);
  letter-spacing: var(--tracking-5);
  font-weight: var(--weight-bold);
  margin-top: 1.5rem;
  max-width: 65ch;
}
.markdown :global(h6:not(.un-text)) {
  font-size: var(--type-scale-4);
  line-height: var(--line-height-4);
  letter-spacing: var(--tracking-4);
  font-weight: var(--weight-bold);
  margin-top: 1.5rem;
  max-width: 65ch;
}
.markdown :global(p:not(.un-text)) {
  font-size: var(--type-scale-4);
  line-height: var(--line-height-4);
  letter-spacing: var(--tracking-4);
  margin-top: 1rem;
  max-width: 65ch;
}
/*# sourceMappingURL=docs/components/Markdown.svelte.map */</style>

<div class="markdown">
	<slot />
</div>
