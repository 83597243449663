<style lang="scss">/* Map Design Tokens to SCSS Variables for easier SCSS design
   ========================================================================== */
/* Mixins & Functions
   ========================================================================== */
.un-split-view {
  --size-split-view-gap: var(--space-1s);
  overflow: hidden;
  height: 100%;
}
.un-split-view__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: calc(var(--size-split-view-gap) / 2 * -1);
  height: calc(var(--space-2) + 100%);
}
.un-split-view__primary, .un-split-view__secondary {
  margin: calc(var(--size-split-view-gap) / 2);
}
.un-split-view__primary {
  -ms-flex-preferred-size: 12.5rem;
      flex-basis: 12.5rem;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.un-split-view__secondary {
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -webkit-box-flex: 999;
      -ms-flex-positive: 999;
          flex-grow: 999;
  min-width: calc(50% - var(--size-split-view-gap));
}
/*# sourceMappingURL=src/components/SplitView.svelte.map */</style>

<div class="un-split-view">
	<div class="un-split-view__wrapper">
		<div class="un-split-view__primary">
			<slot name="primary" />
		</div>
		<div class="un-split-view__secondary">
			<slot name="secondary" />
		</div>
	</div>
</div>
