<script>
	export let label;

	import Label from "./Label.svelte";
</script>

<style lang="scss">/* Map Design Tokens to SCSS Variables for easier SCSS design
   ========================================================================== */
/* Mixins & Functions
   ========================================================================== */
.un-control {
  position: relative;
}
/*# sourceMappingURL=src/components/Control.svelte.map */</style>

<div class="un-control">
	<Label text="{label}">
		<slot />
	</Label>
</div>
