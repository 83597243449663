<script>
	export let space = "2";
</script>

<style lang="scss">/* Map Design Tokens to SCSS Variables for easier SCSS design
   ========================================================================== */
/* Mixins & Functions
   ========================================================================== */
.un-stack.has-space-1 :global(> * + *) {
  margin-top: var(--space-1);
}
.un-stack.has-space-2 :global(> * + *) {
  margin-top: var(--space-2);
}
.un-stack.has-space-3 :global(> * + *) {
  margin-top: var(--space-3);
}
.un-stack.has-space-4 :global(> * + *) {
  margin-top: var(--space-4);
}
.un-stack.has-space-5 :global(> * + *) {
  margin-top: var(--space-5);
}
.un-stack.has-space-6 :global(> * + *) {
  margin-top: var(--space-6);
}
.un-stack.has-space-7 :global(> * + *) {
  margin-top: var(--space-7);
}
.un-stack.has-space-8 :global(> * + *) {
  margin-top: var(--space-8);
}
/*# sourceMappingURL=src/components/Stack.svelte.map */</style>

<div
	class="un-stack"
	class:has-space-1="{space === '1'}"
	class:has-space-2="{space === '2'}"
	class:has-space-3="{space === '3'}"
	class:has-space-4="{space === '4'}"
	class:has-space-5="{space === '5'}"
	class:has-space-6="{space === '6'}"
	class:has-space-7="{space === '7'}"
	class:has-space-8="{space === '8'}"
>
	<slot />
</div>
