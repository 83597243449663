<script>
	import { createEventDispatcher } from "svelte";
	import { text } from "svelte/internal";

	export let size = "1";
	export let type = "primary";
	export let variant = "default";

	const dispatch = createEventDispatcher();

	function onClick(event) {
		dispatch("click", event);
	}
</script>

<style lang="scss">/* Map Design Tokens to SCSS Variables for easier SCSS design
   ========================================================================== */
/* Mixins & Functions
   ========================================================================== */
.un-button {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  vertical-align: middle;
  color: inherit;
  font: inherit;
  border: 0;
  background: transparent;
  padding: 0;
  margin: 0;
  outline: 0;
  border-radius: 0;
  text-align: inherit;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: 0 var(--space-3);
  border-radius: var(--size-border-radius-1);
  font-weight: var(--weight-bold);
  border-width: var(--size-border-1);
  border-style: solid;
  border-color: transparent;
  cursor: pointer;
}
.un-button.is-size-1 {
  font-size: var(--type-scale-2);
  line-height: var(--line-height-2);
  letter-spacing: var(--tracking-2);
  height: 1.5rem;
}
.un-button.is-size-2 {
  font-size: var(--type-scale-3);
  line-height: var(--line-height-3);
  letter-spacing: var(--tracking-3);
  font-weight: var(--weight-bold);
  height: 2rem;
}
.un-button.is-type-primary {
  background-color: var(--color-neutral-700);
  border-color: var(--color-neutral-700);
  color: var(--color-white);
}
.un-button.is-type-primary:hover {
  background-color: var(--color-neutral-700);
  border-color: var(--color-neutral-700);
}
.un-button.is-type-primary:active {
  background-color: var(--color-neutral-800);
  border-color: var(--color-neutral-800);
}
.un-button.is-type-primary.is-variant-brand {
  background-color: var(--color-primary-600);
  border-color: var(--color-primary-600);
  color: var(--color-white);
}
.un-button.is-type-primary.is-variant-brand:hover {
  background-color: var(--color-primary-700);
  border-color: var(--color-primary-700);
}
.un-button.is-type-primary.is-variant-brand:active {
  background-color: var(--color-primary-800);
  border-color: var(--color-primary-800);
}
.un-button.is-type-secondary {
  border-color: var(--color-neutral-600);
}
.un-button.is-type-secondary:hover {
  background-color: var(--color-neutral-600);
}
.un-button.is-type-secondary:active {
  background-color: var(--color-neutral-800);
  border-color: var(--color-neutral-800);
}
.un-button.is-type-secondary.is-variant-brand {
  border-color: var(--color-primary-600);
}
.un-button.is-type-secondary.is-variant-brand:hover {
  background-color: var(--color-primary-600);
}
.un-button.is-type-secondary.is-variant-brand:active {
  background-color: var(--color-primary-800);
  border-color: var(--color-primary-800);
}
.un-button.is-type-ghost {
  background-color: transparent;
  border-color: transparent;
}
.un-button.is-type-ghost:hover {
  background-color: var(--color-neutral-600);
}
.un-button.is-type-ghost:active {
  background-color: var(--color-neutral-800);
}
.un-button.is-type-ghost.is-variant-brand {
  background-color: transparent;
  border-color: transparent;
}
.un-button.is-type-ghost.is-variant-brand:hover {
  background-color: var(--color-primary-600);
}
.un-button.is-type-ghost.is-variant-brand:active {
  background-color: var(--color-primary-800);
}
/*# sourceMappingURL=src/components/Button.svelte.map */</style>

<button
	class="un-button"
	class:is-size-1="{size === '1'}"
	class:is-size-2="{size === '2'}"
	class:is-type-primary="{type === 'primary'}"
	class:is-type-secondary="{type === 'secondary'}"
	class:is-type-ghost="{type === 'ghost'}"
	class:is-variant-brand="{variant === 'brand'}"
	on:click="{onClick}"
>
	<slot />
</button>
