<script>
	export let width = "1";
</script>

<style lang="scss">/* Map Design Tokens to SCSS Variables for easier SCSS design
   ========================================================================== */
/* Mixins & Functions
   ========================================================================== */
.un-divider hr {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  height: 0;
  overflow: visible;
  border: 0;
  border-top: 1px solid var(--color-bg-accent);
  margin: 0;
  clear: both;
  color: inherit;
}
.un-divider.is-width-2 hr {
  border-width: 2px;
}
/*# sourceMappingURL=src/components/Divider.svelte.map */</style>

<div class="un-divider" class:is-width-2="{width === '2'}">
	<hr />
</div>
