<script>
	export let space = "1";
</script>

<style lang="scss">/* Map Design Tokens to SCSS Variables for easier SCSS design
   ========================================================================== */
/* Mixins & Functions
   ========================================================================== */
.un-inline.has-space-1 {
  margin-left: calc(-1 * var(--space-1));
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.un-inline.has-space-1::before {
  content: "";
  display: block;
  margin-top: calc(-1 * var(--space-1));
}
.un-inline.has-space-1 :global(> *) {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: top;
  margin-left: var(--space-1);
  margin-top: var(--space-1);
}
.un-inline.has-space-2 {
  margin-left: calc(-1 * var(--space-2));
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.un-inline.has-space-2::before {
  content: "";
  display: block;
  margin-top: calc(-1 * var(--space-2));
}
.un-inline.has-space-2 :global(> *) {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: top;
  margin-left: var(--space-2);
  margin-top: var(--space-2);
}
.un-inline.has-space-3 {
  margin-left: calc(-1 * var(--space-3));
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.un-inline.has-space-3::before {
  content: "";
  display: block;
  margin-top: calc(-1 * var(--space-3));
}
.un-inline.has-space-3 :global(> *) {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: top;
  margin-left: var(--space-3);
  margin-top: var(--space-3);
}
.un-inline.has-space-4 {
  margin-left: calc(-1 * var(--space-4));
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.un-inline.has-space-4::before {
  content: "";
  display: block;
  margin-top: calc(-1 * var(--space-4));
}
.un-inline.has-space-4 :global(> *) {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: top;
  margin-left: var(--space-4);
  margin-top: var(--space-4);
}
.un-inline.has-space-5 {
  margin-left: calc(-1 * var(--space-5));
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.un-inline.has-space-5::before {
  content: "";
  display: block;
  margin-top: calc(-1 * var(--space-5));
}
.un-inline.has-space-5 :global(> *) {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: top;
  margin-left: var(--space-5);
  margin-top: var(--space-5);
}
.un-inline.has-space-6 {
  margin-left: calc(-1 * var(--space-6));
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.un-inline.has-space-6::before {
  content: "";
  display: block;
  margin-top: calc(-1 * var(--space-6));
}
.un-inline.has-space-6 :global(> *) {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: top;
  margin-left: var(--space-6);
  margin-top: var(--space-6);
}
.un-inline.has-space-7 {
  margin-left: calc(-1 * var(--space-7));
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.un-inline.has-space-7::before {
  content: "";
  display: block;
  margin-top: calc(-1 * var(--space-7));
}
.un-inline.has-space-7 :global(> *) {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: top;
  margin-left: var(--space-7);
  margin-top: var(--space-7);
}
.un-inline.has-space-8 {
  margin-left: calc(-1 * var(--space-8));
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.un-inline.has-space-8::before {
  content: "";
  display: block;
  margin-top: calc(-1 * var(--space-8));
}
.un-inline.has-space-8 :global(> *) {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: top;
  margin-left: var(--space-8);
  margin-top: var(--space-8);
}
/*# sourceMappingURL=src/components/Inline.svelte.map */</style>

<div
	class="un-inline"
	class:has-space-1="{space === '1'}"
	class:has-space-2="{space === '2'}"
	class:has-space-3="{space === '3'}"
	class:has-space-4="{space === '4'}"
	class:has-space-5="{space === '5'}"
	class:has-space-6="{space === '6'}"
	class:has-space-7="{space === '7'}"
	class:has-space-8="{space === '8'}"
>
	<slot />
</div>
